import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";

import Recherche from "../assets/recherche.svg";
import ResearchSVG from "../assets/research.svg";
import ProduitsSVG from "../assets/produits.svg";
import PublicationsSVG from "../assets/publications.svg";

import LogoPhysioassist from "../assets/physioassist2.png";
import LogoCHU from "../assets/chu-rouen-normandie.png";
import LogoGRHV from "../assets/grhv.png";
import LogoAntadir from "../assets/antadir.png";

// markup
const IndexPage = () => {
  return (
    <Layout title="Accueil">
      <section className=" text-coolGray-800">
        <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-around">
          <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
            <p className="mt-6 mb-8 text-5xl font-bold text-coolGray-800 sm:mb-12 tracking-wide">
              Laboratoire <span className="text-orange-500">expert</span> et{" "}
              <span className="text-orange-500">indépendant</span> au service de
              la santé respiratoire
            </p>
            <div>
              <p className="text-lg mb-8">
                Évaluer les performances des dispositifs médicaux sur banc
                d'essai est notre métier depuis 2016.
                <br /> Notre expertise pour développer des protocoles sur mesure
                répondant à des problématiques scientifiques ou de développement
                produit nous permet de vous accompagner dans vos projets
                innovants.
              </p>
            </div>
            <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
              <a
                href="#contact"
                className="px-8 py-3 text-lg font-semibold bg-orange-500 rounded text-coolGray-50"
              >
                Découvrir le laboratoire
              </a>
            </div>
          </div>
          <div className="flex lg:flex-col items-center justify-evenly lg:w-5/6 lg:max-w-md sm:w-full sm:flex-row xl:max-w-lg">
            <Recherche className="object-cover object-center" />
          </div>
        </div>
      </section>
      <section className="py-3 m-4 bg-orange-100 md:m-8 text-coolGray-800">
        <div className="container p-4 mx-auto my-6 space-y-1 text-center">
          <span className="text-xs font-semibold tracking-wider text-orange-500 uppercase">
            nos spécialités
          </span>
          <h2 className="pb-3 text-3xl font-bold md:text-4xl">
            Découvrez nos différents domaines d'expertise autour du dispositif
            médical
          </h2>
        </div>
        <div className="container grid justify-center gap-4 mx-auto text-center lg:grid-cols-2 xl:grid-cols-3">
          <Link to="/recherche">
            <div className="flex flex-col px-8 py-6 transition-shadow hover:shadow">
              <h2 className="mb-2 text-lg font-semibold sm:text-xl title-font text-coolGray-800">
                Banc d'essais
              </h2>
              <ResearchSVG className="self-center flex-1 w-1/2 mb-4" />
              <Link
                className="inline-flex items-center justify-center space-x-2 text-sm text-orange-500"
                to="/recherche"
              >
                <span>En savoir plus</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
          <Link to="/produits">
            <div className="flex flex-col px-8 py-6 transition-shadow hover:shadow">
              <h2 className="mb-2 text-lg font-semibold sm:text-xl title-font text-coolGray-800">
                Logiciels et analyses
              </h2>
              <ProduitsSVG className="self-center flex-1 w-1/2 mb-4" />
              <Link
                className="inline-flex items-center justify-center space-x-2 text-sm text-orange-500"
                to="/produits"
              >
                <span>En savoir plus</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
          <Link to="http://papers.kernelbiomedical.com">
            <div className="flex flex-col px-8 py-6 transition-shadow hover:shadow">
              <h2 className="mb-2 text-lg font-semibold sm:text-xl title-font text-coolGray-800">
                Publication scientifique
              </h2>
              <PublicationsSVG className="self-center flex-1 w-1/2 mb-4" />
              <Link
                className="inline-flex items-center justify-center space-x-2 text-sm text-orange-500"
                to="/services"
              >
                <span>En savoir plus</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
        </div>
      </section>

      <section className="text-coolGray-800">
        <div className="container px-6 py-12 mx-auto">
          <div className="grid items-center gap-4 xl:grid-cols-5">
            <div className="max-w-2xl mx-auto my-8 space-y-4 text-center xl:col-span-2 xl:text-left">
              <h2 className="text-4xl font-bold">
                Ils nous ont fait confiance
              </h2>
              <p className="text-coolGray-600">
                pour leurs projets académiques ou industriels
              </p>
            </div>
            <div className="p-6 xl:col-span-3">
              <div className="grid gap-4 md:grid-cols-2">
                <div className="grid content-center gap-4">
                  <div className="p-6 rounded shadow-md bg-coolGray-50">
                    <div className="flex justify-evenly">
                      <img
                        src={LogoPhysioassist}
                        alt="PhysioAssist"
                        className="mb-4 w-56"
                      />
                    </div>
                    <p className="text-lg font-semibold">
                      Société PhysioAssist
                    </p>
                    <p>
                      Évaluation des critères de safety et de performance pour
                      un dispositif médical innovant d'aide au désencombrement
                      bronchique
                    </p>
                  </div>
                  <div className="p-6 rounded shadow-md bg-coolGray-50">
                    <div className="flex justify-evenly">
                      <img
                        src={LogoCHU}
                        alt="CHU Rouen Normandie"
                        className="mb-4 w-36 h-16"
                      />
                      <img src={LogoGRHV} alt="GRHV" className="mb-4 w-32 " />
                    </div>
                    <p className="text-lg font-semibold">
                      Service de pneumologie du CHU de Rouen
                    </p>
                    <p>
                      Performances hygrométriques des humidificateurs chauffants
                      et impact sur le travail respiratoire en VNI
                    </p>
                    <p>
                      Développement d'un modèle expérimental d'évaluation des
                      interfaces de ventilation non invasive
                    </p>
                  </div>
                </div>
                <div className="grid content-center gap-4">
                  <div className="p-6 rounded shadow-md bg-coolGray-50">
                    <div className="flex justify-evenly">
                      <img
                        src={LogoAntadir}
                        alt="Antadir"
                        className="mb-4 w-56"
                      />
                    </div>
                    <p className="text-lg font-semibold">Fédération ANTADIR</p>
                    <p>
                      Partenaire pour le référencement des nouveaux dispositifs
                      de ventilation non invasive et d'oxygénation pulsée
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
